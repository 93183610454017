#catalog-view {
	padding-top: 16px;
	padding-bottom: 16px;

	// border: 5px solid green;

	#catalog {
		height: calc(100vh - 150px);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		padding-bottom: 25px;
		overflow-y: auto;

		// border: 1px solid red;
	}
}

@media only screen and (min-width: 1025px) {
	#catalog-view {
		#catalog {
			height: calc(100vh - 167px);
			padding: 45px;
			gap: 15px;
		}
	}
}

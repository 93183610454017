#transfer-list {
	flex-direction: column;

	.transfer-paper {
		width: 200px;
		height: 230px;
		overflow: auto;

		&.dark {
			background-color: var(--background);
			color: var(--txt-primary);
			box-shadow: 0px 2px 1px -1px rgba(211, 211, 211, 0.2),
				0px 1px 1px 0px rgba(211, 211, 211, 0.14),
				0px 1px 3px 0px rgba(211, 211, 211, 0.12);
		}
	}
}

@media only screen and (min-width: 568px) {
	#transfer-list {
		flex-direction: row;
	}
}

#auth {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;

	#auth-card {
		border-radius: 20px;
		border: 3px ridge rgb(240, 139, 76);

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 26px 26px 30px 26px;

			form {
				display: flex;
				flex-direction: column;
				align-items: center;

				.auth-btn {
					width: 100%;
					background-color: rgb(4, 65, 100);
					color: whitesmoke;

					&:hover {
						background-color: rgb(231, 139, 90);
					}
				}
			}

			.icon {
				color: var(--success);
			}

			.link {
				color: var(--link);
				font-size: 0.8rem;
				font-weight: bold;
				text-decoration: none;
			}
		}
	}
}

#post {
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	.wrapper {
		padding: 20px;
		display: flex;
		flex-direction: column;
		background-color: var(--card-background);
		color: var(--text-primary);

		.delete-icon {
			color: red;
		}

		.post-actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 15px;
		}

		#update-form {
			padding: 20px;
			padding-top: 0;

			.MuiDivider-root {
				margin: 20px 0;
			}

			.update-form-group {
				display: flex;
				flex-direction: row;
				margin-bottom: 15px;
				justify-content: center;

				.update-input {
					border: none;
					padding: 10px;
					background-color: lightgrey;
					border-radius: 20px;
					resize: none;

					&:focus {
						outline: none;
					}
				}

				.carousel-preview {
					width: 100%;
					display: flex;
					justify-content: center;
				}

				&.topic,
				&.transfer,
				&.images,
				&.show {
					flex-direction: column;
					align-items: center;
					gap: 25px;
				}
			}

			.update-actions {
				display: flex;
				justify-content: flex-end;
				gap: 15px;
			}
		}

		.watch-container {
			padding: 35px 16px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.post-video-container {
				width: 100%;
				height: 15px;
				position: relative;
				padding-top: 56.25%;

				iframe {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}

		.post-vitals {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			h2 {
				margin-top: 10px;
				font-family: 'Lora', serif;
				color: #666;
			}

			h5 {
				color: #b39656;
				text-indent: 5px;
			}

			.date-author {
				display: flex;
				flex-direction: column;
				color: #b39656;
				align-items: flex-end;

				.post-date {
					font-size: 12px;
				}

				.post-author {
					font-size: 13px;
				}
			}
		}

		.post-content-container {
			padding: 20px;
			padding-top: 0;
			max-height: 75vh;
			overflow: auto;

			.post-content {
				color: #666;
				text-wrap: wrap;
				text-indent: 15px;

				&::first-letter {
					font-size: 30px;
					font-weight: 600;
				}
			}
		}

		.image-container,
		.cover-photo-container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 15px;
			margin: 15px 0;
			text-align: left;
			padding: 10px;

			.post-img,
			.cover-photo {
				width: 200px;
				height: 224px;
				position: relative;

				.del-photo-btn {
					position: absolute;
					top: 5px;
					right: 5px;
					background-color: rgba($color: #000000, $alpha: 0.8);
				}

				.badge {
					width: 36px;
					height: 36px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 5px;
					left: 5px;
					border-radius: 100%;
					background-color: rgba($color: #000000, $alpha: 0.8);
					color: whitesmoke;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: fill;
				}
			}
		}

		.carousel-container {
			display: flex;
			justify-content: center;

			&.empty {
				height: 180px;
				align-items: center;
			}
		}
	}
}

@media only screen and (min-width: 896px) {
}

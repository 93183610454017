.featured {
	height: fit-content;
	padding: 20px;
	background-color: seashell;

	.featured-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		.info-container {
			height: fit-content;
			display: flex;
			flex-direction: column;
			gap: 5px;
			padding: 16px;
			text-align: center;
			margin-top: 20px;

			h2 {
				font-size: 35px;
			}

			.lnk-btn {
				align-self: center;
				max-width: 163px;
				padding: 15px 25px;
			}
		}

		.pic-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-content: center;

			img {
				width: 100%;
			}
		}
	}

	.btn-container {
		display: flex;
		justify-content: center;
	}

	&.alt {
		background-color: skyblue;
	}
}

@media only screen and (min-width: 768px) {
	.featured {
		.featured-container {
			flex-direction: row;

			.pic-container,
			.info-container {
				width: 50%;

				img {
					width: 55%;
					align-self: center;
				}
			}

			&.reverse {
				flex-direction: row-reverse;
			}
		}
	}
}

#about {
	display: flex;
	align-items: center;

	.inside-about {
		display: flex;
		justify-content: space-between;

		.txt-container,
		.pic-container {
			width: 45%;
		}

		.txt-container {
			display: flex;
			flex-direction: column;
			gap: 25px;
		}

		.pic-container {
			img {
				width: 100%;
			}
		}
	}
}

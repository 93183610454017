#watch {
	height: fit-content;

	.watch-container {
		padding: 35px 16px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.content-title {
			width: 95%;
			margin-bottom: 30px;
			background-color: #218380;
			text-align: center;
			color: var(--background);
			border: 5px solid black;
			border-radius: 20px;
			font-weight: bold;
		}

		.video-container {
			width: 95%;
			height: 15px;
			position: relative;
			padding-top: 56.25%;

			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

#user-view {
	padding-top: 16px;
	padding-bottom: 16px;

	#table-container {
		height: calc(100vh - 150px);
		overflow-y: auto;

		#users-table {
			max-width: fit-content;
			margin: 0 auto;
		}
	}
}

@media only screen and (min-width: 1025px) {
	#user-view {
		#table-container {
			height: calc(100vh - 167px);
			padding: 25px;
		}
	}
}

#hero {
	text-align: center;

	h1 {
		font-size: 64px;
	}

	.hero-btn {
		width: 250px;
		padding: 25px;
		border-radius: 5px;
	}
}

.opt-in-label {
	// width: fit-content;
	padding: 2.5px 5px;
	border-radius: 7px;
	font-weight: bold;
	// border: 1px solid black;

	&.positive {
		background-color: rgba(0, 167, 111, 0.16);
		color: rgb(0, 120, 103);
	}

	&.negative {
		background-color: rgba(255, 86, 48, 0.16);
		color: rgb(183, 29, 24);
	}
}

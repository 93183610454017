.interest {
	max-width: 500px;
	padding: 35px;
	margin: auto;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;

		.submit {
			color: dodgerblue;
		}
	}

	.name-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.disclose-container {
		font-size: 10px;
		text-align: center;
	}
}

@media only screen and (min-width: 400px) {
	.interest {
		.name-container {
			flex-direction: row;
			gap: 10px;
		}
	}
}

#member-home {
	position: relative;

	section {
		height: fit-content;
		// min-height: 55vh;
	}

	#bonus {
		background-color: mintcream;
	}

	.featured {
		&.even {
			background-color: seashell;
		}

		&.odd {
			background-color: skyblue;
		}
	}

	#self-advert {
		background-color: rgb(143, 45, 86);
	}
}

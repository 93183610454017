#reset {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;

	#reset-password {
		padding-bottom: 20px;
		border-radius: 20px;
		border: 3px ridge rgb(240, 139, 76);

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 26px 26px 0px 26px;

			form {
				display: flex;
				flex-direction: column;

				.reset-btn {
					background-color: rgb(4, 65, 100);
					color: whitesmoke;

					&:hover {
						background-color: rgb(231, 139, 90);
					}
				}
			}

			.icon {
				color: var(--success);
			}
		}

		.response-container {
			max-width: 70%;
			margin: 0 auto;

			p {
				line-height: 1.5;
				font-weight: bold;
				font-size: 13px;

				span {
					vertical-align: middle;
					margin-right: 5px;
				}
			}
		}
	}
}

#business-card {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;

	.canvas {
		padding: 20px;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		.info-box {
			.name {
				color: #e78b5a;
			}

			.info {
				color: #044164;
			}
		}

		.img-divider {
			width: 60%;
			margin: 40px 0;
			background-color: #044164;
		}

		.qr-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			img {
				display: none;
			}
		}

		a {
			margin-top: 15px;
		}
	}
}

@media only screen and (min-width: 600px) {
	#business-card {
		.canvas {
			width: 475px;
			height: fit-content;
			min-height: 240px;
			flex-direction: row;

			.info-box,
			.code-box {
				width: 45%;
			}

			.img-divider {
				display: none;
			}

			.code-box {
				img {
					display: block;
					width: 80%;
					margin-bottom: 20px;
				}
			}
		}
	}
}

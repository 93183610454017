#blog {
	padding-top: 16px;
	padding-bottom: 16px;

	.main {
		height: calc(100vh - 150px);
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		padding-top: 25px;
		padding-bottom: 25px;
		overflow-y: auto;
	}

	// .wrapper {
	// 	height: calc(100vh - 140px);
	// 	padding: 20px;
	// 	display: flex;
	// 	flex-direction: column;

	// 	.page-header {
	// 		display: flex;
	// 		justify-content: flex-end;
	// 		flex-shrink: 0;

	// 		#create-new-btn {
	// 			&:hover {
	// 				// background-color: lightgoldenrodyellow;
	// 			}
	// 		}
	// 	}

	// 	.heading {
	// 		background-color: blanchedalmond;
	// 		margin: 25px 0;
	// 		padding: 15px;
	// 		flex-shrink: 0;
	// 	}

	// 	.main {
	// 		flex: 1;
	// 		flex-shrink: 0;
	// 		border: 5px ridge lightgray;
	// 		border-radius: 10px;
	// 		overflow-y: scroll;
	// 	}
	// }
}

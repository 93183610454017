#profile {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	// border: 2px solid dodgerblue;

	.header-container {
		.cover-photo-section {
			height: 180px;
			display: flex;
			justify-content: center;
			background-color: orange;
			position: relative;

			.cover-photo-container {
				width: 100%;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}

				.cover-photo-btn {
					width: inherit;
					height: 180px;
					display: none;
					position: absolute;
					left: 0;
					bottom: 0;

					.btn-icon {
						font-size: 50px;
						color: rgba(0, 0, 0, 0.6);
					}
				}

				&:hover .cover-photo-btn {
					display: block;
				}
			}
		}
	}

	#profile-content {
		position: relative;

		.user-container {
			position: absolute;
			top: -66px;

			.user-image-container {
				width: 132px;
				height: 132px;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					border: 4px solid var(--background);
					background-color: grey;
					object-fit: cover;
					object-position: center;
				}

				.profile-pic-btn {
					position: absolute;
					width: inherit;
					height: inherit;
					display: none;

					.btn-icon {
						font-size: 50px;
						color: rgba(0, 0, 0, 0.6);
					}
				}

				&:hover .profile-pic-btn {
					display: block;
				}
			}
		}

		.profile-paper {
			height: fit-content;
			display: flex;
			flex-direction: column;
			margin-top: 136px;
			padding: 16px;
			margin-bottom: 50px;
			background-color: var(--card-background);
			color: var(--text-primary);
			overflow-y: auto;

			.greeting-container {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding: 10px;
				margin-bottom: 10px;
				// border: 1px solid dodgerblue;
			}

			form {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-top: 10px;
				// border: 1px solid green;

				.basic,
				.phone,
				.comm {
					// margin-left: 16px;
					margin-bottom: 15px;
				}

				.phone,
				.comm {
					margin-top: 15px;
				}

				.grid-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					// border: 1px solid rebeccapurple;
				}
			}
		}
	}
}

@media only screen and (min-width: 600px) {
	#profile {
		#profile-content {
			.profile-paper {
				form {
					.basic,
					.phone,
					.comm {
						margin-left: 20px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 900px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1536px) {
}

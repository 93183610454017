.pressable {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: var(--card-background) !important;

	.vitals {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title-tag {
			display: flex;
			flex-direction: column;

			h3 {
				margin-top: 10px;
				font-family: 'Lora', serif;
				font-size: 16px;
				color: #666;
			}

			h6 {
				color: #b39656;
				font-size: 10px;
				text-indent: 5px;
			}
		}

		.date-author {
			display: flex;
			flex-direction: column;
			color: #b39656;
			align-items: flex-end;

			.blog-date {
				font-size: 9px;
			}

			.blog-author {
				font-size: 11px;
			}
		}
	}

	.preview-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 0 10px;

		.limited-txt {
			align-self: flex-start;
			line-height: 1.2em; /* Adjust based on your font-size */
			max-height: 6em; /* Adjust based on your line-height and line count */
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			line-clamp: 3;
			-webkit-line-clamp: 3; /* Limits text to 3 lines */
			text-overflow: ellipsis;
			white-space: normal;
			color: #666;

			& p::first-letter {
				margin-left: 15px;
				font-size: 25px;
				font-weight: 600;
			}
		}

		.attribute-container {
			width: 100%;
			position: absolute;
			right: 0;
			bottom: 0;
			align-self: flex-end;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 20px;
			// border: 2px solid purple;

			h6 {
				color: var(--text-secondary);
			}
		}
	}
}

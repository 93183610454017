#about-me {
	height: fit-content;

	#about-header {
		background: rgb(113, 49, 101);
		padding: 20px 0;

		.header-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.txt-container {
				display: flex;
				flex-direction: column;
				text-align: center;

				h1 {
					font-size: 50px;
					color: var(--background);
				}
			}

			.pic-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-content: center;
				background-color: gray;
				border: 10px solid var(--background);

				img {
					width: 100%;
				}
			}
		}
	}

	#about-description {
		.description-container {
			padding: 35px;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}
}

@media only screen and (min-width: 768px) {
	#about-me {
		#about-header {
			.header-container {
				flex-direction: row;

				.txt-container,
				.pic-container {
					width: 50%;

					img {
						width: 55%;
						align-self: center;
					}
				}
			}
		}
	}
}

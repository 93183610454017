#coming {
	background: url('../../../public/grid.jpg') no-repeat center;
	background-size: cover;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 25px;
	color: whitesmoke;
}

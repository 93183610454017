#member-hero {
	min-height: 52.5vh;
	padding: 20px 0;
	background: rgb(113, 49, 101);
	background: linear-gradient(
		135deg,
		rgba(113, 49, 101, 1) 0%,
		rgba(174, 62, 69, 1) 66%,
		rgba(203, 70, 36, 1) 100%
	);

	.info-box {
		padding: 20px;
		background-color: rgba(255, 255, 255, 0.2);
		text-align: center;
		border-radius: 5px;

		h4 {
			font-style: italic;
			font-size: 21px;
		}

		p {
			font-size: 20px;
		}
	}
}

@media only screen and (min-width: 768px) {
	#member-hero {
		.info-box {
			max-width: 343px;
		}
	}
}

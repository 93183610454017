footer {
	width: 100%;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgb(143, 45, 86);

	.copyright {
		margin-bottom: 15px;
	}

	.links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 15px;

		a {
			text-decoration: none;
			color: var(--text-primary);
		}
	}
}

@media only screen and (min-width: 768px) {
	footer {
		height: 89.2px;
		flex-direction: row;
		justify-content: space-between;

		.copyright {
			margin-bottom: 0;
		}

		.links {
			flex-direction: row;
		}
	}
}

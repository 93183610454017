.app {
	height: fit-content;
	min-height: 100vh;
	background-color: var(--background);
	color: var(--text-primary);
	transition: all 0.5s;

	.MuiPaper-root.MuiPaper-elevation.dark {
		box-shadow: 0px 6px 6px -3px rgba(255, 255, 255, 0.2),
			0px 10px 14px 1px rgba(255, 255, 255, 0.14),
			0px 4px 18px 3px rgba(255, 255, 255, 0.12);
	}

	.MuiTableContainer-root {
		scrollbar-width: thin;
	}
}

#home {
	height: fit-content;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	section {
		width: 100%;
		height: fit-content;
		padding: 20px;

		&#hero {
			height: 55vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			// background-color: rgba(223, 235, 236);
			background: radial-gradient(
				ellipse at center,
				rgb(194, 215, 220) 15%,
				rgb(225, 236, 240) 74%,
				rgb(225, 236, 240) 74%,
				rgb(242, 246, 249) 100%
			);
		}

		&#about {
			height: 60vh;
			background-color: rgba(255, 255, 255);
		}

		&#offering {
			height: 60vh;
			background-color: rgba(223, 235, 236);
		}
	}
}

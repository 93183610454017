button {
	margin: 10px 0;
	padding: 8px;
	border-radius: 40px;
	border: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	background-color: transparent;
	cursor: pointer;

	&.blog-btn {
		position: absolute;
		left: 20px;
		color: rgb(4, 65, 100);
	}

	&.main-topic-btn {
		width: 100%;
		color: white;
		background-color: dodgerblue;
	}
}

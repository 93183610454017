.pic-btn {
	height: 300px;
	overflow: hidden;
	position: relative;

	.pic-wrapper {
		height: 100%;
		width: fit-content;
		overflow: hidden;
		margin: 0 auto;

		img {
			width: 100%;
			height: 100%;
			object-fit: fill;
			display: block;
		}
	}
}

.slide {
	width: 90%;
	align-self: center;

	.slick-arrow::before {
		color: black !important;
	}
}

#nav-content {
	display: flex;
	flex-direction: column;

	#logo-container {
		width: 234px;
		height: 64px;
		background-color: #16161a;
		display: flex;
		flex-direction: column;
		justify-content: center;
		cursor: pointer;

		img {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1025px) {
	#nav-content {
		#logo-container {
			width: 280px;
			height: 80px;
		}
	}
}

@media only screen and (min-width: 1266px) {
}

@media only screen and (min-width: 1536px) {
}

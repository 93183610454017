h1 {
	margin: 20px 0;
}
.canvas {
	padding: 25px;

	form {
		display: flex;
		flex-direction: column;

		.submit-btn {
			color: rgb(25, 118, 210);
		}
	}
}

:root {
	--brand: #daa520; //goldenrod
	--background: #f2f4f6;
	--text-primary: #16161a;
	--text-secondary: #7f5af0;
	--accent: #2cb67d;
	--link: steelblue;
	--success: green;
	--error: red;
	--spacing: 15px;
}

[data-theme='dark'] {
	--brand: #b8860b; // darkgoldenrod
	--background: #353535;
	--text-primary: #f2f4f6;
	--text-secondary: #94a1b2;
	--card-background: #1f1f1f;
	--accent: darkred;
}

* {
	margin: 0;
	padding: 0;
	scroll-snap-align: start;
	box-sizing: border-box;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
}

.lnk-btn {
	background-color: rgb(4, 65, 100);
	color: whitesmoke;

	&:hover {
		background-color: rgb(231, 139, 90);
	}
}

.success,
.error {
	text-align: center;
}

.success {
	color: var(--success);
}

.error {
	color: var(--error);
}

#create-post {
	padding: 20px;
	overflow: auto;

	.page-title {
		text-align: center;
		padding-top: 20px;
	}

	form {
		display: flex;
		flex-direction: column;
		padding: 20px;

		.MuiDivider-root {
			margin: 20px 0;
		}

		.create-form-group {
			display: flex;
			flex-direction: row;
			margin-bottom: 15px;
			justify-content: center;

			.create-input {
				border: none;
				padding: 10px;
				margin: 10px 0;
				background-color: lightgrey;
				border-radius: 20px;
				resize: none;

				&:focus {
					outline: none;
				}
			}

			&.topic,
			&.transfer {
				flex-direction: column;
				align-items: center;
				gap: 25px;
			}
		}

		.create-submit {
			width: 150px;
			padding: 7px;
			align-self: flex-end;
			background-color: steelblue;
		}
	}
}

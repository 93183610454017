.select-input {
	width: 100%;
	border-radius: 10px;
	margin: 10px;
	margin-top: 0;
	padding: 5px;

	h6 {
		margin-left: 7px;
		margin-bottom: 5px;
	}

	.select-container {
		display: flex;
		align-items: center;
		border-radius: 10px;
		border: 1px solid;
		padding: 5px;

		select {
			width: 100%;
			text-align: center;
			padding: 12px;
			outline: none;
			border: none;
			background-color: transparent;
			color: var(--txt-primary);
		}
	}
}

#main-content {
	width: 100vw;
	height: calc(100vh - 64px);
	position: absolute;
	top: 64px;
	right: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	// border: 2px solid green;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1025px) {
	#main-content {
		width: calc(100vw - 280px);
		height: calc(100vh - 80px);
		top: 80px;
	}
}

@media only screen and (min-width: 1266px) {
}

@media only screen and (min-width: 1536px) {
}
